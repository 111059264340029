<template>
    <div class="coianter">
        <div class="idteail">
        <!-- <div class="idtname">江苏省工程建设地方标准编制系统</div> -->
            <div class="el-icon-back"  @click="$router.go(-1)"></div>
        </div>
        <div class="table main-box" >
            <el-form ref="form" :label-position="labelPosition" :model="form" label-width="150px" >
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="项目名称"   prop="proName"
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.proName" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="标准类型"  prop="biaozhunType" 
                            :rules="{
                                required: true,
                                message: '请选择类型',
                                trigger: 'change',
                            }">
                                <el-radio-group v-model="form.biaozhunType">
                                    <el-radio :label="1" >标准</el-radio>
                                    <el-radio :label="2" >标准设计</el-radio>
                                    <!-- <el-radio :label="3" >推荐性技术规程</el-radio> -->
                                </el-radio-group>
                            </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="英文名称" >
                        <el-input v-model="form.USname" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="制定类型"  prop="zhidingType" 
                            :rules="{
                                required: true,
                                message: '请选择类型',
                                trigger: 'change',
                            }">
                                <el-radio-group v-model="form.zhidingType">
                                    <el-radio :label="1" >制定</el-radio>
                                    <el-radio :label="2" >修订</el-radio>
                                    <!-- <el-radio :label="3" >推荐性技术规程</el-radio> -->
                                </el-radio-group>
                            </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="第一主编单位"   prop="firstUnit"
                        :rules="{
                            required: true,
                            message: '请填写单位',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstUnit" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="地址(邮编)"   prop="firstAddress"
                        :rules="{
                            required: true,
                            message: '请填写地址',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstAddress" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="单位负责人"   prop="firstHandler"
                        :rules="{
                            required: true,
                            message: '请填写负责人',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstHandler" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话(手机)"   prop="firstTel"
                        :rules="{
                            required: true,
                            message: '请填写联系电话',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstTel"  style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="电子邮箱"   prop="firstEmail"
                        :rules="{
                            required: true,
                            message: '请填写电子邮箱',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstEmail"  style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="项目负责人"   prop="firstProHandler"
                        :rules="{
                            required: true,
                            message: '请填写负责人',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstProHandler" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话(手机)"   prop="firstProHandlerTel"
                        :rules="{
                            required: true,
                            message: '请填写联系电话',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstProHandlerTel" style="width:100%" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="电子邮箱"   prop="firstProHandlerEmail"
                        :rules="{
                            required: true,
                            message: '请填写电子邮箱',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstProHandlerEmail" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="项目联系人"   prop="firstProContact"
                        :rules="{
                            required: true,
                            message: '请填写联系人',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstProContact" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话(手机)"   prop="firstProContactTel"
                        :rules="{
                            required: true,
                            message: '请填写联系电话',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstProContactTel" style="width:100%" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="电子邮箱"   prop="firstProContactEmail"
                        :rules="{
                            required: true,
                            message: '请填写电子邮箱',
                            trigger: 'blur',
                        }">
                        <el-input v-model="form.firstProContactEmail" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="第二主编单位"  >
                        <el-input v-model="form.secUnit" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="地址(邮编)" >
                        <el-input v-model="form.secHandleEmail" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="单位负责人" >
                        <el-input v-model="form.secHandler" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话(手机)" >
                        <el-input v-model="form.secTel" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="电子邮箱" >
                        <el-input v-model="form.secEmail" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="项目负责人"  >
                        <el-input v-model="form.secProHandler" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话(手机)"  >
                        <el-input v-model="form.secProTel" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="电子邮箱">
                        <el-input v-model="form.secProEmail" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <el-form-item label="参编单位"  >
                        <el-input v-model="form.join_companies" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <div style="font-size: 20px;color: #666666;">主要起草人员 </div>
                    </el-col>
                </el-row>
                <div v-for="(item,index) in form.fu" :key="index">
                    <el-row>
                        <el-col :span="4">
                            <el-form-item label="姓名"  >
                            <el-input v-model="form.fu[index].parter" style="width:130px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="所学专业" >
                            <el-input v-model="form.fu[index].career" style="width:115px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="现从事专业">
                            <el-input v-model="form.fu[index].careerNow" style="width:130px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="职称/职务"   >
                            <el-input v-model="form.fu[index].job" style="width:115px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="工作单位" >
                            <el-input v-model="form.fu[index].work" style="width:130px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="承担工作" >
                            <el-input v-model="form.fu[index].take" style="width:115px;"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-row >
                    <el-col :offset="22">
                        <el-button type="primary" style="margin-top:60px;width:120px;height:50px;font-size:20px;" @click="addform">新增</el-button>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="8">
                        <div style="margin-bottom:50px;font-size: 20px;color: #666666;">编制大纲</div>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <el-form-item label="一、目的、意义"   prop="purpose" 
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                            <el-input type="textarea" rows="6" v-model="form.purpose"   maxlength="2500" @input="descInput" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <el-form-item label="二、适用范围和 主要技术内容"   prop="content"
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                            <el-input type="textarea" rows="6" v-model="form.content"   maxlength="2500" @input="descInput"  ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <el-form-item label="三、现有工作基础 和需解决的重点问 题,必要的测试 验证项目"   prop="problem"
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                            <el-input type="textarea" rows="6" v-model="form.problem"   maxlength="2500" @input="descInput"  ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <el-form-item label="四、与有关法律法规、相关标准的关系"   prop="law"
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                            <el-input type="textarea" rows="6" v-model="form.law"    maxlength="2500" @input="descInput" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="2">
                        <el-form-item label="五、经费组成"  
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                        </el-form-item>
                    </el-col>
                    <el-col :span="4"> 
                        <el-form-item label="拨款"   prop="bokuan"
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                            <el-input v-model="form.bokuan"  style="width:115px;">
                                <i slot="suffix" style="margin:10px;font-style:normal;">万元</i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5"> 
                        <el-form-item label="自筹"   prop="zichou"
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                            <el-input v-model="form.zichou"  style="width:115px;">
                                <i slot="suffix" style="margin:10px;font-style:normal;">万元</i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <el-form-item label="六、社会经济效益分析"   prop="xiaoyi"
                        :rules="{
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur',
                        }">
                            <el-input type="textarea" rows="6" v-model="form.xiaoyi"   maxlength="2500" @input="descInput"  ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="2">
                        <el-form-item label="七、进度计划" >
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="起止时间"   prop="start_time1"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.start_time1"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="起止时间"   prop="start_time2"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.start_time2"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="起止时间"   prop="start_time3"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.start_time3"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="起止时间"   prop="start_time4"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.start_time4"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="起止时间"   prop="start_time5"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.start_time5"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="至"   prop="endtime1"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.endtime1"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="至"   prop="endtime2"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.endtime2"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="至"   prop="endtime3"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.endtime3"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="至"   prop="endtime4"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.endtime4"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="至"   prop="endtime5"
                        :rules="{
                            required: true,
                            message: '请填写时间',
                            trigger: 'change',
                        }">
                            <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                v-model="form.endtime5"
                                style="width:220px;"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="工作内容"   prop="worktext1">
                            <el-input v-model="worktext1" disabled style="width:100%"></el-input>
                        </el-form-item>
                        <el-form-item label="工作内容"   prop="worktext2">
                            <el-input v-model="worktext2" disabled style="width:100%"></el-input>
                        </el-form-item>
                        <el-form-item label="工作内容"   prop="worktext3">
                            <el-input v-model="worktext3" disabled style="width:100%"></el-input>
                        </el-form-item>
                        <el-form-item label="工作内容"   prop="worktext4">
                            <el-input v-model="worktext4" disabled style="width:100%"></el-input>
                        </el-form-item>
                        <el-form-item label="工作内容"   prop="worktext5">
                            <el-input v-model="worktext5" disabled style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="comit">
        <div class="baocun">
            <el-button type="primary" @click="saveProject()">保存</el-button>
        </div>
        <div class="baocun">
            <el-button type="primary" @click="tijiaoProject()">提交</el-button>
        </div>
        </div>
    </div>
</template>
<script>
// addBiaozhun
import { getBiaozhunDetail,addBiaozhun} from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";
// const validatePhone = (rule, value, callback) => {
//   if (value === "") {
//     callback(new Error("请输入手机号"));
//   } else {
//     if (!/^1[3456789]\d{9}$/.test(value)) {
//       callback(new Error("请输入正确的手机号"));
//     } else {
//       callback();
//     }
//   }
// };
export default {
  // 手机号验证

    data() {
        return {
            labelPosition: "right",
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            fileList: [],
            form: {
                proName:'',
                biaozhunType:'',
                USname:'',
                zhidingType:'',
                firstUnit:'',
                firstAddress:'',
                firstHandler:'',
                firstTel:'',
                firstEmail:'',
                firstProHandler:'',
                firstProHandlerTel:'',
                firstProHandlerEmail:'',
                secUnit:'',
                secTel:'',
                secEmail:'',
                secHandler:'',
                secHandleEmail:'',
                secProHandler:'',
                secProTel:'',
                secProEmail:'',
                purpose:'',
                content:'',
                problem:'',
                law:'',
                bokuan:'',
                zichou:'',
                xiaoyi:'',
                start_time1 :'',
                endtime1:'',
                start_time2 :'',
                endtime2:'',
                start_time3 :'',
                endtime3:'',
                start_time4 :'',
                endtime4:'',
                start_time5 :'',
                endtime5:'',
                worktext1:'完成编制大纲及技术条件分析',
                worktext2:'完成初稿',
                worktext3:'完成征求意见稿',
                worktext4:'完成送审稿',
                worktext5:'完成报批稿',
                ren:[],
                fu:[],
                qicaoform:{
                    parter:'',
                    career:'',
                    careerNow:'',
                    job:'',
                    work:'',
                    take:''
                },
                
            },
            qicao: {
                    xiangqing: [{
                        parter:'',
                        career:'',
                        careerNow:'',
                        job:'',
                        work:'',
                        take:''
                    }],
                },
            cityOptions: JiangSu.city,
            countyOptions: [],
            value1:'',
            value2:'',
            value3:'',
            id:'',
            token:'',
            worktext1:'完成编制大纲及技术条件分析',
            worktext2:'完成初稿',
            worktext3:'完成征求意见稿',
            worktext4:'完成送审稿',
            worktext5:'完成报批稿',
        };
    },
    mounted() {
        this.getIdAndToken();
    },
    methods: {
        getIdAndToken(){
            this.id = this.$route.query.id;
            this.token = this.$store.state.token//获取token
            this.getInfo();
        },
        addform(){
            this.form.fu.push({
            })
            console.log(this.form.ren)
        },
        //获取详情
        getInfo() {
            getBiaozhunDetail({id:this.id,token:this.token}).then((res) => {
                //let ren=[];
                this.form = {
                    token:this.token,
                    //ren:ren,
                    ...res.data
                }
                // const r = res.data.fu.filter(item => item.parter !=null); //过滤空数组
                // this.form.qicaoform = r[0];   //获取第一位主要人员
                // let a = r.slice(1)//slice() 浅克隆去复制 arr 元素从第二个开始到最后一个
                // this.qicao.xiangqing = a
                console.log(res)
                }).catch((err) => {
                console.log(err)
            });
        },

         //保存
        saveProject(){
            // let copyRen = this.form.fu
            this.form.status =1  //保存状态
            // console.log(this.form)
            this.form.ren = this.form.fu
            console.log('console.log(this.form)',this.form)
            addBiaozhun(this.form).then(res =>{
                console.log('rec',res)
            })
            this.$router.push({
            path: '../../user_project/itemIdent'
            })
                
        },
        //提交项目
        tijiaoProject() {
            console.log(this.form)
            let copyRen = this.form.fu
            this.form.status =2  //保存状态
            this.$refs.form.validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.form.ren = this.form.fu
                    addBiaozhun(this.form).then(rec =>{
                        console.log(rec)
                    })
                    this.$router.push({
                        path: '../../user_project/itemIdent'
                    })
                }else {
                    this.form.fu = copyRen
                    this.$message({
                        showClose: true,
                        message: "请填写完整信息",
                        type: "warning",
                    });
                    console.log('this.form.ren',this.form.ren);
                    return false;
                }
            });
        },
        descInput(){
            if(this.form.purpose.length>=2500){
                this.$message({
                    showClose: true,
                    message: "一、目的、意义字数限制2500",
                    type: "warning",
                });
            }
            if(this.form.content.length>=2500){
                this.$message({
                    showClose: true,
                    message: "二、适用范围和主要技术内容字数限制2500",
                    type: "warning",
                });
            }
            if(this.form.problem.length>=2500){
                this.$message({
                    showClose: true,
                    message: "三、现有工作基础和需解决的重点问题,必要的测试验证项目，字数限制2500",
                    type: "warning",
                });
            }
            if(this.form.law.length>=2500){
                this.$message({
                    showClose: true,
                    message: "四、与有关法律法规、相关标准的关系，字数限制2500",
                    type: "warning",
                });
            }
        }
        // handleRemove(file) {
        // console.log(file, "2");
        // },
        // handlePictureCardPreview(file) {
        // this.dialogImageUrl = file.url;
        // this.dialogVisible = true;
        // },
        // handleDownload(file) {
        // console.log(file, "1");
        // },
    },
};
</script>
<style scoped>
/deep/ .el-input {
  width: 250px;
}
</style>
<style lang="scss" scoped>
.coianter {
  background-color: #ffffff;
  // height: 100%;
}

.idteail {
  margin: 4px;
  width: 100%;
//   height: 50px;
  line-height: 30px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
  }
}

.table {
  margin: 30px 50px;
}

.comit {
  padding: 94px 0;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 25px;
  }
}
.name_input {
  width: 100%;
}
.name_input > .el-form-item {
  width: 100%;
}
.name_input > .el-form-item .el-input {
  width: 100%;
}
</style>
